<script setup lang="ts">
const { isDemoMode } = useDemoStore()
</script>

<template>
  <div class="flex flex-col justify-center items-center w-full h-full">
    <img
      src="~assets/spinners/ap_spinner_blue.svg"
      alt="generating"
      class="w-7/12 mx-auto"
    >
    <span
      v-if="isDemoMode"
      class="text-6xl font-extrabold text-slate-600 capitalize"
    >{{ $t('shared.demo-mode') }}</span>
  </div>
</template>
